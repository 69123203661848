// src/components/CountdownTimer.js
import React, { useState, useEffect } from "react";
// import "../../styles/pages/_thank-you-new.scss"

const CountdownTimer = () => {
    const [remainingTime, setRemainingTime] = useState(600); // 600 seconds = 10 minutes

    useEffect(() => {
        const interval = setInterval(() => {
            setRemainingTime((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return {
            minutes: minutes.toString().padStart(2, "0"),
            seconds: seconds.toString().padStart(2, "0")
        };
    }

    return (
        <>
            <div>
                <p>{remainingTime > 0 ? formatTime(remainingTime).minutes : '00'}</p>
                <span>Minutes</span>
            </div>
            <div>
                <p>{remainingTime > 0 ? formatTime(remainingTime).seconds : '00'}</p>
                <span>Seconds</span>
            </div>
        </>
    );
};

export default CountdownTimer;

